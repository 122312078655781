import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutDefault from '@/layouts/default'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutDefault,
    redirect: {name: 'Home'},
    children: [
      {
        path: 'dashboard',
        name: 'Home',
        component: () => import('@/views/home')
      },
      {
        path: 'usuarios',
        name: 'Users',
        component: () => import('@/views/admin/users/list')
      },
      {
        path: 'perfil',
        name: 'Profile',
        component: () => import('@/views/profile')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/page-not-found'),
    name: 'PageNotFound'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
