import api from "@/store/api";
import moment from 'moment'

const millennium = {
  namespaced: true,
  state: () => ({
    status: 0,
    salesStatus: 0,
    salesComparativeStatus: 0,
    salesChartStatus: 0,
    auth: {},
    sales: [],
    startDate: getISODate(new Date(`${new Date().getFullYear()}-01-01`).toLocaleDateString()),
    endDate: getISODate(new Date().toLocaleDateString()),
    currentStartDate: getISODate(new Date().toLocaleDateString()),
    currentEndDate: getISODate(new Date().toLocaleDateString()),
    previousStartDate: getISODate(moment(new Date()).subtract(1,'days').format('DD/MM/YYYY')),
    previousEndDate: getISODate(moment(new Date()).subtract(1,'days').format('DD/MM/YYYY')),
    salesTotal: {amount: 0, quant: 0, average: 0},
    salesTodayTotalQuant: 0,
    salesTodayTotal: {amount: 0, quant: 0, average: 0, sales: 0},
    salesMonthTotal: {},
    salesLastYears: {},
    salesComparativeTotal: {A: {amount: 0, quant: 0, average: 0}, B: {amount: 0, quant: 0, average: 0}},
    salesFilialsTotal: [],
    salesFilialsComparativeTotal: {A: [], B: []},
    months: ['jan','fev','mar','abr','mai','jun','jul','ago','set','out','nov','dez'],
    datetimeUpdate: '',
    comparatives: []
  }),
  
  mutations: {
    setAuth: (state, payload) => state.auth = payload,
    setStatus: (state, payload) => state.status = payload,
    setSalesStatus: (state, payload) => state.salesStatus = payload,
    setSalesChartStatus: (state, payload) => state.salesChartStatus = payload,
    setSalesComparativeStatus: (state, payload) => state.salesComparativeStatus = payload,
    setSales: (state, payload) => state.sales = payload,
    setStartDate: (state, payload) => state.startDate = payload,
    setEndDate: (state, payload) => state.endDate = payload,
    setCurrentStartDate: (state, payload) => state.currentStartDate = payload,
    setCurrentEndDate: (state, payload) => state.currentEndDate = payload,
    setPreviousStartDate: (state, payload) => state.previousStartDate = payload,
    setPreviousEndDate: (state, payload) => state.previousEndDate = payload,
    setSalesTotal: (state, payload) => state.salesTotal = reducerSalesTotal(payload),
    setSalesTodayTotal: (state, payload) => state.salesTodayTotal = reducerSalesTotal(payload),
    setSalesTodayTotalQuant: (state, payload) => state.salesTodayTotalQuant = payload,
    setSalesMonthTotal: (state, payload) => state.salesMonthTotal = reducerGroupMonths(state.months, payload),
    setSalesLastYears: (state, {year, data}) => state.salesLastYears[year] = reducerGroupMonths(state.months, data, () => state.salesChartStatus = 0),
    setSalesComparativeTotal: (state, payload) =>  state.salesComparativeTotal[payload.key] = reducerSalesTotal(payload[`data${payload.key}`]),
    setComparatives: (state) => reducerSetComparatives(state),
    // setSalesFilialsTotal: (state, payload) => state.salesFilialsTotal = reducerGroupFilialTotal(payload),
    // setSalesFilialsComparativeTotal: (state, payload) => state.salesFilialsComparativeTotal[payload.key] = reducerGroupFilialTotal(payload.data),
    setDateTimeUpdate: (state) => {
      const date = new Date();
      state.datetimeUpdate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString().substr(0,5)
    }
  },
  
  actions: {
    getSales({commit}, {type, startDate, endDate}) {
      return new Promise((resolve, reject) => {
        commit('setSalesStatus', 1)
        commit('setSalesChartStatus', 1)
        commit('setSalesComparativeStatus', 1)
        setTimeout(() => {
          api.get(
            `/sales?startDate=${startDate}&endDate=${endDate}`
          )
            .then(res => {
              const data = res.data.content
              resolve(data)
              switch (type) {
                case 'today':
                  commit('setSalesTodayTotal', data);
                  commit('setSalesTodayTotalQuant', data.length);
                  break;
                case 'months':
                  commit('setSalesMonthTotal', data);
                  break;
                case 'years':
                  commit('setSalesComparativeStatus', 0)
                  commit('setSalesLastYears', {year: startDate.split('-')[0], data});
                  break;
                default:
                  commit('setSalesTotal', data)
                  commit('setSalesFilialsTotal', data)
              }
              commit('setSalesStatus', 0)
            })
            .catch(err => {
              commit('setSalesStatus', -1)
              reject(err.response)
            })
        }, 1000)
      })
    },
    getSalesMock({commit, state}, {startDate, endDate}) {
      return new Promise((resolve, reject) => {
        commit('setSalesStatus', 1)
        setTimeout(() => {
          commit('setSalesStatus', 0)
          console.log(startDate, endDate)
          if(state.salesStatus < 0) {
            reject({status: 500})
          } else {
            resolve()
          }
        }, 4000)
        
      })
    },
    getComparativeSales({commit, state}, {currentStartDate, currentEndDate, previousStartDate, previousEndDate}) {
      
      return new Promise((resolve, reject) => {
        commit('setStatus', 1)
        commit('setSalesComparativeStatus', 1)
        
        api.get(
          `/sales?startDate=${currentStartDate}&endDate=${currentEndDate}`
        )
          .then(res => {
            const dataA = res.data.content
            commit('setSalesComparativeTotal', {key: 'A', dataA})
            
            api.get(
              `/sales?startDate=${previousStartDate}&endDate=${previousEndDate}`
            )
              .then(res => {
                const dataB = res.data.content
                resolve(dataA, dataB)
                commit('setStatus', 0)
                commit('setSalesComparativeStatus', 0)
                commit('setSalesComparativeTotal', {...state.salesComparativeTotal, ...{key: 'B', dataB}})
              })
              .catch(err => {
                commit('setStatus', -1)
                reject(err.response)
              })
          })
          .catch(err => {
            commit('setStatus', -1)
            reject(err.response)
          })
      })
    },
    getComparativeSalesMock({commit, state}, {currentStartDate, currentEndDate, previousStartDate, previousEndDate}) {
      
      return new Promise((resolve, reject) => {
        commit('setStatus', 1)
        setTimeout(() => {
          commit('setStatus', 0)
          console.log({currentStartDate, currentEndDate, previousStartDate, previousEndDate})
          if(state.status < 0) {
            reject()
          } else {
            resolve()
          }
        }, 2000)
        
      })
    }
  }
}

export default millennium


function reducerSalesTotal(data) {
  return data.reduce((reducer, it) => {
    reducer.amount += it.valor;
    reducer.quant += it.qtde;
    reducer.average = reducer.quant === 0 ? 0 : reducer.amount / reducer.quant
    return reducer;
  },{amount: 0, quant: 0, average: 0, sales: data.length})
}


function reducerGroupMonths(months, data, callback) {
  const totalMonths = {amount: {}, quant: {}, average: {}, sales: {}};
  months.forEach((month,i) => {
    totalMonths.amount[month] = data.reduce((sum,it) => {if(Number(it.mes) === (i+1)) {sum += it.valor} return sum; }, 0)
    totalMonths.sales[month] = data.reduce((sum,it) => {if(Number(it.mes) === (i+1)) {sum = sum+1} return sum; }, 0)
    totalMonths.quant[month] = data.reduce((sum,it) => {if(Number(it.mes) === (i+1)) {sum += it.qtde} return sum; }, 0)
    totalMonths.average[month] = totalMonths.sales[month] === 0 ? 0 : totalMonths.amount[month] / totalMonths.sales[month]
    if((month.length - 1) === i) callback && callback()
  })
  return totalMonths
}

function reducerSetComparatives(state) {
  const getComparativeValues = (key, indicator) => {
    return state.salesComparativeTotal.length === 0 ? 0.0 : state.salesComparativeTotal[key][indicator]
  }
  
  state.comparatives = [
    {
      title: 'Vendas',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      amountA: getComparativeValues('A','amount'),
      amountB: getComparativeValues('B','amount'),
      borderColor: '#d58d33'
    },
    {
      title: 'Quantidade',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      quantA: getComparativeValues('A','quant'),
      quantB: getComparativeValues('B','quant'),
      borderColor: '#68addf'
    },
    {
      title: 'Total de Vendas',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      quantA: getComparativeValues('A','sales'),
      quantB: getComparativeValues('B','sales'),
      borderColor: '#398137'
    },
    {
      title: 'Tiket Médio',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      amountA: getComparativeValues('A','average'),
      amountB: getComparativeValues('B','average'),
      borderColor: '#515ebb'
    },
  ]
}

// function reducerGroupFilialTotal(data) {
//   return data.reduce((reducer, it) => {
//
//     const item = reducer.find(e => e.cod === it.cod_filial) || {cod: 0, amount: 0, qtde: 0, desc: ''};
//
//     item.amount += it.valor;
//     item.qtde += it.qtde;
//     item.desc = it.nome_filial;
//
//     if(item.cod) {
//       item.cod = it.cod_filial;
//     } else {
//       item.cod = it.cod_filial;
//       reducer.push(item);
//     }
//
//     return reducer;
//
//   }, [])
// }


function getISODate(date) {
  return date.split('/').reverse().join('-')
}
